<template>
  <div>
    <BTable small
            hover
            striped
            bordered
            responsive
            selectable
            select-mode="single"
            selected-variant="danger"
            sticky-header
            sticky-column
            ref="drvTable"
            id="drvTable"
            thead-class="text-center"
            class="text-nowrap small text-center"
            :fields="fields"
            :items="rows"
            primary-key="_id"
            head-variant="dark"
            :key="tableKey"
            @row-selected="rowSelected"
    >

      <template #cell(drvKey)="{item}">
        <BButton size="sm" class="mt-1"
                 :class="{blink: isBlink(item.stsElapsed, item.sts) || item.updElapsed < 3 }"
                 :variant="drvKeyVariant(item.drvElapsed, item.sts)" >
          <del v-if="item.isCancled">{{item.drvKey.slice(-11)}}</del>
          <strong v-if="!item.isCancled">{{item.drvKey.slice(-11)}}</strong>
        </BButton>
      </template>

      <template #cell(drvDt)="{item}">
        {{item.drvYmd}} <b>{{item.drvHms.substring(0,5)}}</b>
        <Br/>
        <BBadge class="ml-1 font-sm"
                :variant="item.drvElapsed < 120?'danger':'light' ">
          {{item.elapsedStr}}
        </BBadge>
      </template>

      <template #cell(centerCode)="{item}">
        {{$store.state.user.center.centerName}}
        <Br/>
        <BBadge variant="dark">{{item.centerCode}}</BBadge>
      </template>

      <template #cell(drvCode)="{item}">
        <BButton pill
                 class="mt-1"
                 style="min-width:100px"
                 size="sm"
                 :variant="drvCdVariant[item.drvCode]">
          {{drvCdMap[item.drvCode]}}
          <BBadge v-show="item.isRoundTrip" class="text-dark ml-1" variant="warning">왕복</BBadge>
        </BButton>
      </template>


      <template #cell(sts)="{item}">
        <b class="text-white font-weight-bold">{{stsMap[item.sts]}}</b>
        <br>
        <BBadge variant="dark">{{isoToHHmm(item.stsDt)}}</BBadge>
        <!--        <BButton size="sm" class="m-0 p-0"></BButton>-->
      </template>

      <template #cell(reqName)="{item}">
        {{item.reqName}}<br/>
        <BBadge>{{toPhoneMask(item.reqHpNo)}}</BBadge>
      </template>

      <template #cell(acqName)="{item}">
        {{item.acqName}}<br/>
        <BBadge>{{toPhoneMask(item.acqHpNo)}}</BBadge>
      </template>

      <template #cell(routes)="{item}">
        <BRow>
          <BCol class="text-left">
            <BBadge variant="primary">출발지</BBadge> {{item.stAddr}} {{item.stAddrDtl}}
          </BCol>
        </BRow>

        <BRow v-for="(rt) in item.routes" :key="rt.idx">
          <BCol class="text-left">
            <BBadge>경유지 {{rt.idx}}</BBadge> {{rt.addr}} {{rt.addrDtl}}
          </BCol>
        </BRow>

        <BRow>
          <BCol class="text-left">
            <BBadge variant="success">도착지</BBadge> {{item.edAddr}} {{item.edAddrDtl}}
          </BCol>
        </BRow>
      </template>

      <template #cell(driverName)="{item}">
        {{item.driverName}}<br/>
        <BBadge>{{toPhoneMask(item.driverHpNo)}}</BBadge>
      </template>

      <template #cell(reqMessage)="{item}">
        <BRow v-if="item.isCnclReq"
              class="bg-black">
          <BCol>
            <BIconExclamationTriangleFill class="text-warning" v-show="item.isCnclReq"/>
            <span class="text-danger font-weight-bolder">&nbsp; 예약 취소 요청 </span>
          </BCol>
        </BRow>
        {{item.reqMessage}}
      </template>


    </BTable>

    <div v-if="selectedDr!==null">
      <request-form :item="selectedDr"
                    :key="reqFormKey"
                    @saved="drSaved"/>
    </div>


  </div>
</template>
<script>
import moment from "moment";
import '../../../common/HelperMixin'
import {apiCall, cloneVar, commify, toastSync} from '../../../common/utils';
import con from '../../../common/constants'
import RequestForm from './RequestForm.vue'

export default {
  name: 'DriveTable',
  props: {
    fromDays : {type: Number, default: 3 },
    min: { type: Boolean, default:false },
  },
  components: {
    RequestForm
  },
  data () {
    return {
      timer: null,
      rows: [],
      selectedDr: null,
      reqFormKey: 0,
      tableKey: 0,
      drvCdVariant: con.drvCode.variant,
      drvCdMap: con.drvCode.map,
      stsMap: con.sts.map,
      fields: [
        { key: 'drvKey', label: '예약번호', sortable: true },
        { key: 'drvDt', label: '예약일시', sortable: true },
        // { key: 'drvYmd', label: '예약일', sortable: true},
        // { key: 'drvHms', label: '시간', sortable: true},
        { key: 'centerCode', label: '센터명' },
        { key: 'drvCode', label: '배차구분' },
        // { key: 'isRoundTrip', label: '왕복' },
        { key: 'sts', label: '상태', tdClass: ( value, key) => {return this.stsCellStyle(value,key)} },
        { key: 'reqName', label: '출발지' }, // reqName, reqHpNo, stAddr, stAddrDtl
        { key: 'acqName', label: '도착지' }, // acqName, acqHpNo, edAddr, edAddrDtl
        { key: 'routes', label: '경로' }, // routes
        { key: 'carKind', label: '차량정보' }, // carKind, plateNo
        { key: 'driverName', label: '배정 정보' }, // driverName, driverHpNo
        // { key: 'totAmt', label: '운임요금', formatter:v=>{return commify(v)} }, // driverName, driverHpNo
        { key: 'reqMessage', label: '요청내용' }, // driverName, driverHpNo
        { key: 'toCenterMsg', label: '메세지' },


        // { key: 'stsDt', label: '상태일시', formatter: this.toLocalDate }, // driverName, driverHpNo
        { key: 'opName', label: '처리자' }, // opId, opName
        { key: 'regId', label: '등록자' }, // driverName, driverHpNo

      ],
      randomKey: 0,
      minFields: [],
      stsColorMap: {
        '00':'color:white',
        '10':'color:red',
        '11':'',
        '20':'',
        '29':'',
        '30':'',
        '31':'',
        '32':'',
        '33':'',
        '99':'',
      },
      payCodeMap: con.payCode.map,
      payCodeOpts: con.payCode.opts,
    }
  },
  created(){
  },

  async mounted(){
    await this.getDriveList();
    if (this.timer) clearInterval(this.timer)
    this.timer = setInterval(this.updateElapsed, 1000 * 30)
    setTimeout(()=> {
      this.initSocket();
    }, 3000);
  },
  methods: {
    initSocket(){
      this.socket = this.$store.state.socket;
      console.log("[DriverTable] initSocket() socket --------------> ", this.socket);
      if (this.socket) {
        this.socket.on('drv-message', this.drvEventHandler);
      }
    },
    drvEventHandler(msg){
      const {rowSelected} = this.$refs.drvTable;
      console.log( 'refs.drvTables ---> ', this.$refs.drvTable );

      console.log('drvEventHandler()-----msg--->', msg);
      const drv = msg.DAT;
      const idx = this.rows.findIndex(x => x._id === drv._id);
      console.log('drvEventHandler()-----row idx--->', idx );
      if(idx===-1){
        this.rows.unshift(drv);
      }else{
        this.rows[idx] = drv;
      }
      this.toastDrvMsg(msg);
      this.tableKey++;

      console.debug( 'devEventHandler ---- selected=', rowSelected );
      if(rowSelected && idx>-1) this.$refs.drvTable.selectRow(idx);

    },

    async getDriveList(){
      try{
        this.rows = [];
        const rs = await apiCall('get', `/api/drv-req/query?fromDays=${this.fromDays}` );
        if(rs.code===200) {
          this.updateElapsed(rs.result)
          this.rows = rs.result;
          // console.log( 'record count--->',  );
        } else {
          await toastSync(this.$bvToast, rs.message)
        }
      }catch(err){
        console.error(err);
      }
    },
    updateElapsed(arr = this.rows){
      console.log( "---------updateElapsed----------" )
      for( let e of arr ) this.setElapsed(e);
      // this.tableKey++;
    },



    stsCellStyle( value ){
      switch (value) {
        case '00': return 'bg-success'
        case '10': return ['bg-dark','text-warning']
        case '11': return ['bg-warning','text-dark']
        case '20': return 'bg-primary'
        case '29': return 'bg-danger'
        case '30': return 'bg-info'
        case '31': return 'bg-info'
        case '32': return 'bg-info'
        case '33': return 'bg-info'
        case '99': return 'bg-secondary'
        default:
          return 'bg-dark'
      }
    },
    /**
     * 깜박임 설정
     * @param sm 상태 변경후 초과시간(분)
     * @param sts 상태
     * @returns {boolean}
     */
    isBlink(sm, sts=''){
      switch(sts){
        case '10': // 탁송 등록
          if(sm > 0 && sm < 10) return true;
          return false;
        case '11': // 접수 확인
          if(sm > 0 && sm < 5) return true;
          return false;
        case '12': // 배차요청
          if(sm > 0 && sm < 5) return true;
          return false;
        case '20': // 기사배차
          if(sm > 0 && sm < 5) return true;
        case '21': // 배차취소 및 재배차
          if(sm > 0 && sm < 5) return true;
          return false;
        case '29': // 배차 취소
          if(sm > 0 && sm < 10) return true;
          return false;
        default:
          if( sm > 0 && sm < 2) return true;
          return false;
      }
    },
    /**
     * 상태 variant 설정
     * @param m 예약시간 기준(경과시간-분)
     * @param sts
     * @returns {string}
     */
    drvKeyVariant(m, sts=''){
      switch(sts){
        case '00': return 'success';
        case '10': return 'primary';
        case '11': return 'outline-info';
        case '12': return 'outline-primary';
        case '21': return 'outline-warning';
        case '20': return 'outline-success';
        case '29': return 'outline-danger';
        case '30': return 'info';
        case '31':
        case '32':
        case '33':
        case '34':
        case '35': return 'info';
        case '99': return 'dark';
      }
    },

    rowSelected(items){
      if(items.length) {
        this.selectedDr = items[0];
        this.reqFormKey++;
      }else{
        this.selectedDr = null;
      }
    },

    setElapsed(r){
      r['drvElapsed'] = moment().diff(moment(r.drvDt), 'minutes');
      r['stsElapsed'] = moment().diff(moment(r.stsDt), 'minutes');
      r['updElapsed'] = moment().diff(moment(r.updatedAt), 'minutes');
      r['elapsedStr'] = this.toElapseStr(r['drvElapsed']);
    },


    async drSaved(rs){
      // console.log( 'selected records ', this.$refs['drvTable'])
       // console.log(e);
      try {
        const idx = this.rows.findIndex(x => x._id === rs.result._id);
        this.rows[idx] = rs.result;
        // console.log( 'drvDt after --->', this.selectedDr.drvDt );
        await this.alertSuccess(rs.message, '예약정보 저장');
        this.$refs.drvTable.clearSelected();
        this.selectedDr = null;
        this.tableKey++;

        //
        // let item = this.drvItems.filter( (r)=>{
        //   return r._id = rs.result._id
        // })
        //
        // item = rs.result;

        // this.tableKey++;


        // await this.getDriveList();

      }catch(err){

      }

    }
  },
  beforeDestroy() {
    if(this.timer) clearInterval(this.timer);
    if(this.socket){
      this.socket.removeListener('drv-message', this.drvEventHandler );
    }
  }
}
</script>
