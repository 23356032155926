<template>
  <div>
    <CCard>
      <CCardHeader>
        <BIconCardList/> 탁송 현황
        <!--
        <CSwitch
          class="float-right"
          variant="3d"
          size="sm"
          shape="pill"
          color="info"
          data-on="On"
          data-off="Off"
          v-model="autoRefresh"
          :checked="true"
        />
        <span class="float-right mr-1">자동 갱신</span>
        -->
        <CSelect size="sm"
                 label="기간"
                 class="float-right mb-0"
                 horizontal
                 :value.sync="drvFromDays"
                 :options="drvFromDaysOpts"
                 @change="tableKey++"
                 />

      </CCardHeader>
      <CCardBody>
        <drive-table
          ref="drvTable"
          :from-days="drvFromDays"
          @row-selected="selectDriveRequest"
          :key="tableKey"
        />
      </CCardBody>
    </CCard>


  </div>
</template>

<script>
import {apiCall, toastSync} from "../../common/utils";
import DriveTable from './components/DriveTable.vue'

import moment from "moment";

export default {
  name: 'Dashboard',
  components: {
    DriveTable,
  },
  data () {
    return {
      autoRefresh: true,
      drvFromDays: 3,
      drvFromDaysOpts: [
        {label:'최근 24시간', value:0},
        {label:'2일', value:2},
        {label:'3일', value:3},
        {label:'일주일', value:7}
      ],

      selectedDr: null,
      drKey: 0,
      tableKey: 0
    }
  },
  async created(){

  },

  methods: {
    selectDriveRequest(item){
      console.log( 'selectDriveRequest', item)
      this.selectedDr = item;
      this.drKey++;
    },


  },
  beforeDestroy() {

  }
}
</script>
